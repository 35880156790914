<template>
  <div class="full-height">
    <v-row justify="center" style="height: 90%">
      <v-col cols="11">
        <v-row class="full-height my-6" style="height: 100%">
          <v-col cols="12">
            <v-card raised height="100%" width="100%" class="pa-3">
              <div v-if="!personalInfoLoading">
                <div class="d-flex">
                  <v-icon
                    v-if="selectedInfo > 0"
                    color="primary"
                    @click="onClickPrevBtn()"
                    >arrow_back</v-icon
                  >
                  <div class="pl-2 text-h5 primary--text font-weight-medium">
                    {{ stepHeading() }}
                  </div>
                </div>
                <BasicInformation
                  ref="basicInfo"
                  v-if="selectedInfo === 0"
                  :personal-info="personalInfoDetails"
                  @file-event-triggered="isFileLoading = $event"
                ></BasicInformation>
                <v-divider v-if="selectedInfo !== 3"></v-divider>
                <v-bottom-sheet
                  v-model="openBottomSheet"
                  hide-overlay
                  persistent
                  no-click-animation
                  width="100%"
                  :retain-focus="false"
                >
                  <v-sheet>
                    <div
                      v-if="selectedInfo !== 3"
                      class="pt-3 pa-1 d-flex justify-end"
                    >
                      <v-btn
                        color="primary"
                        style="border-radius: 10px"
                        large
                        :loading="saveLoading"
                        :disabled="isFileLoading"
                        @click="onSaveBasicInfo()"
                        >Save & Continue</v-btn
                      >
                    </div>
                  </v-sheet>
                </v-bottom-sheet>
              </div>
              <v-overlay
                absolute
                :value="personalInfoLoading"
                :opacity="'0.9'"
                color="#fff"
                z-index="1"
              >
                <v-progress-circular
                  color="primary"
                  indeterminate
                  size="50"
                ></v-progress-circular>
              </v-overlay>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BasicInformation from "./vendorbasicdetails/VendorBasicInformation.vue";

import {
  GET_VENDOR_DETAILS,
  SAVE_VENDOR_DETAILS,
} from "@/graphql/vendorOnboardQueries";

export default {
  name: "VendorBasicDetails",

  components: { BasicInformation },

  data: () => ({
    personalInfoDetails: {},
    personalInfoLoading: true,
    selectedInfo: 0,
    stepDetails: {},
    saveLoading: false,
    isFileLoading: false,
    openBottomSheet: true,
  }),

  apollo: {
    getVendorDetails: {
      query: GET_VENDOR_DETAILS,
      client: "apolloClientB",
      fetchPolicy: "no-cache",
      variables() {
        return {
          urlHash: localStorage.getItem("vendorUrlHash")
            ? localStorage.getItem("vendorUrlHash")
            : localStorage.getItem("urlHash"),
        };
      },
      result({ data }) {
        if (
          data &&
          data.getVendorDetails &&
          data.getVendorDetails.vendorDetails
        ) {
          this.personalInfoDetails = data.getVendorDetails.vendorDetails;
          if (this.personalInfoDetails.onboardingFormId) {
            this.$emit(
              "enable-dynamic-form",
              this.personalInfoDetails.onboardingFormId
            );
          }
        }
        this.personalInfoLoading = false;
      },
      // Error handling
      error(getPersonalInfoErr) {
        let snackbarData = {
          isOpen: true,
          message:
            getPersonalInfoErr.graphQLErrors.length !== 0
              ? getPersonalInfoErr.graphQLErrors[0].message
              : "Something went wrong. It could be a possibility due to network connectivity error..",
          type: "warning",
        };
        this.showAlert(snackbarData);
        this.personalInfoLoading = false;
      },
    },
  },

  methods: {
    stepHeading() {
      return "Vendor Basic Details";
    },

    async onSaveBasicInfo() {
      let isFormValid = await this.validateForms();
      if (isFormValid) {
        let nextStep = this.selectedInfo + 1;
        this.submitPersonalDetails(nextStep);
      }
    },

    async validateForms() {
      return new Promise((resolve) => {
        this.$refs.basicInfo.validateBasicInfo().then((validationResponse) => {
          this.stepDetails = this.$refs.basicInfo.basicInfoFields();
          resolve(validationResponse);
        });
      });
    },

    onClickPrevBtn() {
      this.personalInfoLoading = true;
      this.$apollo.queries.getVendorDetails.refetch();
      this.selectedInfo -= 1;
    },

    submitPersonalDetails(nextStep) {
      let vm = this;
      vm.saveLoading = true;
      let vendorId = parseInt(localStorage.getItem("vendorId"));
      let submitObj = {
        vendorId: vendorId,
        ...vm.stepDetails,
      };
      vm.$apollo
        .mutate({
          mutation: SAVE_VENDOR_DETAILS,
          variables: submitObj,
          client: "apolloClientC",
        })
        .then(() => {
          vm.saveLoading = false;
          vm.selectedInfo = nextStep;
          vm.$emit("basic-details-saved");
        })
        .catch((updateErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              updateErr.graphQLErrors.length !== 0
                ? updateErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
          vm.selectedInfo = nextStep - 1;
          vm.personalInfoLoading = false;
          vm.saveLoading = false;
        });
    },

    // show error or success message
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>
