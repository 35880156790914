<template>
  <div v-if="!dropdownListLoading">
    <BulkImportStep1
      v-if="currentStep === 0"
      :excel-data="formattedFileContent"
      :locationList="locationList['all']"
      :departmentList="departmentList['all']"
      :designationList="designationList['all']"
      :workScheduleList="workScheduleList['all']"
      :empTypeList="empTypeList['all']"
      :managerList="managerList['all']"
      :validityList="validityList['all']"
      @file-upload-success="fileContent = $event"
      @file-upload-error-remove="fileContent = ''"
    ></BulkImportStep1>
    <BulkImportStep2
      v-else-if="currentStep === 1"
      :file-params="fileContent"
      @column-mapped="
        matchedCount = $event[0];
        mappedFileHeader = $event[1];
      "
    ></BulkImportStep2>
    <BulkImportStep3
      ref="bulkImport3"
      v-else-if="currentStep === 2"
      :excel-data="formattedFileContent"
      :locationList="locationList['locationNames']"
      :departmentList="departmentList['departmentNames']"
      :designationList="designationList['designationNames']"
      :workScheduleList="workScheduleList['wsNames']"
      :empTypeList="empTypeList['emTypes']"
      :managerList="managerList['managerNames']"
      :validityList="validityList['vTypes']"
      @errors-count="validationErrorCount = $event"
    ></BulkImportStep3>
    <div class="d-flex my-4">
      <v-btn
        v-if="currentStep !== 0"
        rounded
        color="primary"
        outlined
        @click="currentStep -= 1"
        large
        >Previous</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        v-if="fileContent"
        rounded
        color="primary"
        @click="onNextStep()"
        large
        :disabled="currentStep === 2 && validationErrorCount > 0"
      >
        {{ currentStep === 2 ? "Save" : "Next" }}
      </v-btn>
    </div>
    <AppLoading v-if="isLoading"></AppLoading>
  </div>
  <v-card min-height="200" v-else>
    <v-overlay
      absolute
      :value="dropdownListLoading"
      color="#fff"
      z-index="1"
      opacity="0.8"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="50"
      ></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import BulkImportStep1 from "./bulk-import/BulkImportStep1.vue";
import BulkImportStep2 from "./bulk-import/BulkImportStep2.vue";
const BulkImportStep3 = () => import("./bulk-import/BulkImportStep3.vue");
import {
  LIST_EMPLOYEE_REQUIRED_FIELDS,
  CANDIDATE_BULK_INVITE,
} from "@/graphql/vendorOnboardQueries";
import moment from "moment";

export default {
  name: "BulkImportTemplate",

  components: { BulkImportStep1, BulkImportStep2, BulkImportStep3 },

  data: () => ({
    currentStep: 0,
    fileContent: "",
    matchedCount: 0,
    mappedFileHeader: {},
    validationErrorCount: 0,

    locationList: {},
    departmentList: {},
    designationList: {},
    workScheduleList: {},
    empTypeList: {},
    managerList: {},
    validityList: {},
    dropdownListLoading: true,
    isLoading: false,
  }),

  apollo: {
    listLocation: {
      query: LIST_EMPLOYEE_REQUIRED_FIELDS,
      client: "apolloClientA",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
        };
      },
      result({ data }) {
        if (data) {
          const {
            listLocation,
            listDepartment,
            listDesignation,
            listWorkSchedule,
            listManager,
            listEmployeeType,
            listValidityType,
          } = data;
          this.locationList["all"] = listLocation.location;
          this.departmentList["all"] = listDepartment.department;
          this.designationList["all"] = listDesignation.designation;
          this.workScheduleList["all"] = listWorkSchedule.work_schedule;
          this.managerList["all"] = listManager.managers;
          this.empTypeList["all"] = listEmployeeType.employeeType;
          this.validityList["all"] = listValidityType.validity;
          let locName = [],
            locId = [];
          for (let loc of listLocation.location) {
            locName.push(loc.Location_Name);
            locId.push(loc.Location_Id);
          }
          this.locationList["locationNames"] = locName;
          this.locationList["locationIds"] = locId;
          let deptName = [],
            deptId = [];
          for (let dept of listDepartment.department) {
            deptName.push(dept.Department_Name);
            deptId.push(dept.Department_Id);
          }
          this.departmentList["departmentNames"] = deptName;
          this.departmentList["departmentIds"] = deptId;
          let designName = [],
            designId = [];
          for (let design of listDesignation.designation) {
            designName.push(design.Designation_Name);
            designId.push(design.Designation_Id);
          }
          this.designationList["designationNames"] = designName;
          this.designationList["designationIds"] = designId;
          let wsName = [],
            wsId = [];
          for (let ws of listWorkSchedule.work_schedule) {
            wsName.push(ws.Title);
            wsId.push(ws.WorkSchedule_Id);
          }
          this.workScheduleList["wsNames"] = wsName;
          this.workScheduleList["wsIds"] = wsId;
          let managerNames = [],
            managerIds = [];
          for (let manager of listManager.managers) {
            managerNames.push(manager.Emp_First_Name);
            managerIds.push(manager.Employee_Id);
          }
          this.managerList["managerNames"] = managerNames;
          this.managerList["managerIds"] = managerIds;
          let emTypes = [],
            emTypeIds = [];
          for (let emType of listEmployeeType.employeeType) {
            emTypes.push(emType.Employee_Type);
            emTypeIds.push(emType.EmpType_Id);
          }
          this.empTypeList["emTypes"] = emTypes;
          this.empTypeList["emTypeIds"] = emTypeIds;
          let vTypes = [],
            vIds = [];
          for (let validity of listValidityType.validity) {
            vTypes.push(validity.Type);
            vIds.push(validity.id);
          }
          this.validityList["vTypes"] = vTypes;
          this.validityList["vIds"] = vIds;
        }
        this.dropdownListLoading = false;
      },
      error() {
        this.dropdownListLoading = false;
      },
    },
  },

  computed: {
    // from array to pass in to vue-excel-file
    formattedFileContent() {
      let excelArray = [];
      for (let i = 1; i < this.fileContent.length; i++) {
        // we are using 7 dropdown validation in excelFile, so the row with data count must be greater than 1
        let emptyRows =
          this.fileContent[i].length -
          this.fileContent[i].filter(String).length;
        let rowWithData = this.fileContent[i].length - emptyRows;
        if (rowWithData > 7) {
          let excelData = {
            index: i,
            Location_Name: "",
            Department_Name: "",
            Designation_Name: "",
            Title: "",
            Employee_Type: "",
            Emp_First_Name: "",
            First_Name: "",
            Last_Name: "",
            Email_Address: "",
            Date_Of_Join: "",
            URL_Validity: "",
            URL_Validity_Duration: "",
            Nominate_Admin: "",
          };
          for (let j in this.mappedFileHeader) {
            if (
              this.mappedFileHeader[j].hrapp_header === "Employee First Name"
            ) {
              let empName =
                this.fileContent[i][this.mappedFileHeader[j].file_header];
              excelData.First_Name = empName ? empName : "";
            }
            if (
              this.mappedFileHeader[j].hrapp_header === "Employee Last Name"
            ) {
              let lastName =
                this.fileContent[i][this.mappedFileHeader[j].file_header];
              excelData.Last_Name = lastName ? lastName : "";
            }
            if (this.mappedFileHeader[j].hrapp_header === "Email Address") {
              let email =
                this.fileContent[i][this.mappedFileHeader[j].file_header];
              excelData.Email_Address = email ? email : "";
            }
            if (
              this.mappedFileHeader[j].hrapp_header ===
              "Date Of Join (YYYY-MM-DD)"
            ) {
              let doj =
                this.fileContent[i][this.mappedFileHeader[j].file_header];
              doj = moment(doj).format("YYYY-MM-DD");
              excelData.Date_Of_Join = doj;
            }
            if (this.mappedFileHeader[j].hrapp_header === "Location") {
              let location =
                this.fileContent[i][this.mappedFileHeader[j].file_header];
              excelData.Location_Name = location ? location : "";
            }
            if (this.mappedFileHeader[j].hrapp_header === "Department") {
              let empName =
                this.fileContent[i][this.mappedFileHeader[j].file_header];
              excelData.Department_Name = empName ? empName : "";
            }
            if (this.mappedFileHeader[j].hrapp_header === "Designation") {
              let designation =
                this.fileContent[i][this.mappedFileHeader[j].file_header];
              excelData.Designation_Name = designation ? designation : "";
            }
            if (this.mappedFileHeader[j].hrapp_header === "Work Schedule") {
              let workSchedule =
                this.fileContent[i][this.mappedFileHeader[j].file_header];
              excelData.Title = workSchedule ? workSchedule : "";
            }
            if (this.mappedFileHeader[j].hrapp_header === "Employee Type") {
              let employeeType =
                this.fileContent[i][this.mappedFileHeader[j].file_header];
              excelData.Employee_Type = employeeType ? employeeType : "";
            }
            if (this.mappedFileHeader[j].hrapp_header === "Manager") {
              let manager =
                this.fileContent[i][this.mappedFileHeader[j].file_header];
              excelData.Emp_First_Name = manager ? manager : "";
            }
            if (this.mappedFileHeader[j].hrapp_header === "Nominate as Admin") {
              let nominateAdmin =
                this.fileContent[i][this.mappedFileHeader[j].file_header];
              excelData.Nominate_Admin = nominateAdmin ? nominateAdmin : "No";
            }
            if (this.mappedFileHeader[j].hrapp_header === "URL Validity") {
              let validity =
                this.fileContent[i][this.mappedFileHeader[j].file_header];
              excelData.URL_Validity = validity ? validity : "";
            }
            if (
              this.mappedFileHeader[j].hrapp_header === "URL Validity Duration"
            ) {
              let duration =
                this.fileContent[i][this.mappedFileHeader[j].file_header];
              excelData.URL_Validity_Duration = duration ? duration : "";
            }
          }
          excelArray.push(excelData);
        }
      }
      return excelArray;
    },
  },

  watch: {
    currentStep(val) {
      if (val === 0) {
        this.fileContent = "";
        this.$emit("display-save-continue", true);
      } else {
        this.$emit("display-save-continue", false);
      }
    },
  },

  methods: {
    onNextStep() {
      if (this.currentStep === 0) {
        if (this.fileContent) this.currentStep = 1;
        else {
          let snackbarData = {
            isOpen: true,
            message: "In order to proceed, you need to upload a file",
            type: "warning",
          };
          this.showAlert(snackbarData);
        }
      } else if (this.currentStep === 1) {
        if (this.matchedCount === 13) this.currentStep = 2;
        else {
          let snackbarData = {
            isOpen: true,
            message:
              "In order to proceed, you need to match your column with our predefined columns",
            type: "warning",
          };
          this.showAlert(snackbarData);
        }
      } else if (this.currentStep === 2) {
        let onboardData = this.$refs.bulkImport3.excelTableData;
        if (this.validationErrorCount > 0) {
          let snackbarData = {
            isOpen: true,
            message:
              "In order to submit, you need to clear all the validation errors",
            type: "warning",
          };
          this.showAlert(snackbarData);
        } else if (onboardData.length === 0) {
          let snackbarData = {
            isOpen: true,
            message: "There is no data to upload",
            type: "warning",
          };
          this.showAlert(snackbarData);
        } else {
          this.formOnboardBulkData(onboardData);
        }
      }
    },
    // backend formatted updated data
    formOnboardBulkData(tableData) {
      let onboardBulkData = [];
      for (let data of tableData) {
        let locIndex = this.locationList["locationNames"].indexOf(
            data.Location_Name
          ),
          deptIndex = this.departmentList["departmentNames"].indexOf(
            data.Department_Name
          ),
          designIndex = this.designationList["designationNames"].indexOf(
            data.Designation_Name
          ),
          wsIndex = this.workScheduleList["wsNames"].indexOf(data.Title),
          empTypeIndex = this.empTypeList["emTypes"].indexOf(
            data.Employee_Type
          ),
          managerIndex = this.managerList["managerNames"].indexOf(
            data.Emp_First_Name
          ),
          validityIndex = this.validityList["vTypes"].indexOf(
            data.URL_Validity_Duration
          );
        let onboardBulkObj = {
          Designation_Id: this.designationList["designationIds"][designIndex],
          Department_Id: this.departmentList["departmentIds"][deptIndex],
          Location_Id: parseInt(this.locationList["locationIds"][locIndex]),
          Job_Code: "",
          Date_Of_Join: data.Date_Of_Join,
          EmpType_Id: this.empTypeList["emTypeIds"][empTypeIndex],
          Manager_Id: this.managerList["managerIds"][managerIndex],
          Work_Schedule: this.workScheduleList["wsIds"][wsIndex],
          firstName: data.First_Name,
          lastName: data.Last_Name,
          email: data.Email_Address,
          nominateAsAdmin: data.Nominate_Admin === "Yes" ? 1 : 0,
          expireValue: parseInt(data.URL_Validity),
          expireType: parseInt(this.validityList["vIds"][validityIndex]),
        };
        onboardBulkData.push(onboardBulkObj);
      }
      this.uploadBulkData(onboardBulkData);
    },
    uploadBulkData(bulkData) {
      let vm = this;
      vm.isLoading = true;
      vm.uploadInitiated = true;
      try {
        vm.$apollo
          .mutate({
            mutation: CANDIDATE_BULK_INVITE,
            variables: {
              employeeData: bulkData,
              count: bulkData.length,
              vendorId: parseInt(localStorage.getItem("vendorId")),
            },
            client: "apolloClientC",
          })
          .then(() => {
            let snackbarData = {
              isOpen: true,
              message: "Employee(s) uploaded successfully",
              type: "success",
            };
            vm.showAlert(snackbarData);
            vm.fileContent = "";
            vm.matchedCount = 0;
            vm.mappedFileHeader = {};
            vm.validationErrorCount = 0;
            vm.isLoading = false;
            vm.currentStep = 0;
            vm.fileContent = "";
            vm.$emit("next-step");
          })
          .catch(() => {
            vm.isLoading = false;
            let snackbarData = {
              isOpen: true,
              message:
                "Something went wrong while uploading the candidate details.",
              type: "warning",
            };
            vm.showAlert(snackbarData);
          });
      } catch {
        vm.showSuccessAlert();
      }
    },
    showSuccessAlert() {
      this.isLoading = false;
      let snackbarData = {
        isOpen: true,
        message: "Candidate details uploaded successfully",
        type: "success",
      };
      this.showAlert(snackbarData);
    },
    // show error or success message
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>
