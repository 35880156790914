<template>
  <div class="full-height">
    <div class="full-height">
      <v-row justify="center">
        <v-col cols="11">
          <v-card class="pa-6 mt-8 mb-4" width="100%" raised height="100%">
            <div class="d-flex align-center">
              <v-icon color="primary" @click="onClickPrevBtn()"
                >arrow_back</v-icon
              >
            </div>
            <v-card-text class="text-center px-12">
              <p class="mt-4 text-h6 primary--text">
                By clicking 'Submit' your details will be submitted to
                <span class="font-weight-bold">{{ companyName }}</span
                >. Click 'Back' if you wanted to change any details.
              </p>
              <div class="mt-10">
                <v-btn
                  style="border-radius: 10px"
                  color="primary"
                  large
                  text
                  elevation="4"
                  @click="onClickPrevBtn()"
                  >Back</v-btn
                >
                <v-btn
                  class="ml-2"
                  style="border-radius: 10px"
                  color="primary"
                  large
                  @click="onSubmitForm()"
                  >Submit</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <AppLoading v-if="isLoading"></AppLoading>
  </div>
</template>

<script>
import { SUBMIT_DETAILS } from "@/graphql/vendorOnboardQueries";

export default {
  name: "FormSubmit",

  data: () => ({
    companyName: localStorage.getItem("companyName"),
    isLoading: false,
  }),

  methods: {
    onClickPrevBtn() {
      this.$emit("back-to-prev");
    },

    onSubmitForm() {
      let vm = this;
      vm.isLoading = true;
      vm.$apollo
        .mutate({
          mutation: SUBMIT_DETAILS,
          variables: {
            vendorId: parseInt(localStorage.getItem("vendorId")),
          },
          client: "apolloClientC",
        })
        .then(() => {
          vm.isLoading = false;
          vm.$emit("form-submitted");
        })
        .catch((err) => {
          vm.isLoading = false;
          let snackbarData = {
            isOpen: true,
            message:
              err.graphQLErrors.length != 0
                ? err.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
        });
    },

    // show error or success message
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>
