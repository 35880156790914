<template>
  <div>
    <div class="full-height">
      <v-row justify="center" style="height: 90%">
        <v-col cols="11">
          <v-card class="pa-6 my-4" width="100%" raised height="100%">
            <div v-if="!personalInfoLoading">
              <div class="d-flex align-center">
                <v-icon color="primary" @click="onClickPrevBtn()"
                  >arrow_back</v-icon
                >
                <div class="pl-2 text-h5 primary--text font-weight-medium">
                  Project Resources
                </div>
                <v-spacer></v-spacer>
              </div>
              <v-form v-if="!showBulkImport">
                <ValidationObserver ref="projectResourceForm">
                  <v-row>
                    <v-col cols="12">
                      <v-card flat class="pa-6">
                        <v-row>
                          <v-col cols="12" sm="12" lg="4" md="4" xl="4">
                            <ValidationProvider
                              ref="noOfEmployeesWithinOrganization"
                              v-slot="{ errors }"
                              name="No of employees within organization"
                              rules="required|numeric|max:5"
                            >
                              <v-text-field
                                :id="'noOfEmployeesWithinOrganization'"
                                v-model="noOfEmployeesWithinOrganization"
                                :maxlength="5"
                                outlined
                                :counter="5"
                                label="No of employees within organization*"
                                :error-messages="errors"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" sm="12" lg="4" md="4" xl="4">
                            <ValidationProvider
                              ref="noOfSubcontractors"
                              v-slot="{ errors }"
                              name="No of subcontractors"
                              rules="required|numeric|max:5"
                            >
                              <v-text-field
                                :id="'noOfSubcontractors'"
                                v-model="noOfSubcontractors"
                                :maxlength="5"
                                outlined
                                :counter="5"
                                label="No of subcontractors*"
                                :error-messages="errors"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" sm="12" lg="4" md="4" xl="4">
                            <ValidationProvider
                              ref="noOfEmployeesToBeAllocatedToContract"
                              v-slot="{ errors }"
                              name="No of employees to be allocated to contract"
                              :rules="`required|numeric|max_value:${
                                noOfEmployeesWithinOrganization +
                                noOfSubcontractors
                              }`"
                            >
                              <v-text-field
                                :id="'noOfEmployeesToBeAllocatedToContract'"
                                v-model="noOfEmployeesToBeAllocatedToContract"
                                :maxlength="5"
                                outlined
                                :counter="5"
                                label="No of employees to be allocated to contract*"
                                :error-messages="errors"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </ValidationObserver>
                <v-divider></v-divider>
              </v-form>
              <div v-else>
                <div class="pl-7 my-5 text-h5 primary--text font-weight-medium">
                  Upload Employee List
                </div>
                <v-col cols="12">
                  <BulkImportTemplate
                    ref="bulkImport"
                    @display-save-continue="onDisplaySaveContinue($event)"
                    @next-step="onSkipProjectResource"
                  >
                  </BulkImportTemplate>
                </v-col>
              </div>
              <v-bottom-sheet
                v-model="openBottomSheet"
                hide-overlay
                persistent
                no-click-animation
                width="100%"
                :retain-focus="false"
              >
                <v-sheet
                  ><div class="pt-3 pa-1 d-flex justify-end">
                    <v-btn
                      v-if="showBulkImport"
                      class="mx-3"
                      color="primary"
                      outlined
                      large
                      rounded
                      @click="showBulkImport = false"
                      ><v-icon left> arrow_back </v-icon>
                      Project Resources
                    </v-btn>
                    <v-btn
                      v-if="!showBulkImport"
                      color="primary"
                      style="border-radius: 10px"
                      large
                      :loading="saveLoading"
                      @click="onSaveProjectResource()"
                      >Save & Continue</v-btn
                    >
                    <v-btn
                      class="mx-3"
                      color="primary"
                      style="border-radius: 10px"
                      text
                      elevation="4"
                      large
                      @click="onSkipProjectResource()"
                    >
                      {{
                        showBulkImport ? "Skip Bulk Import & Continue" : "Skip"
                      }}
                    </v-btn>
                  </div>
                </v-sheet>
              </v-bottom-sheet>
            </div>
            <v-overlay
              absolute
              :value="personalInfoLoading"
              :opacity="'0.9'"
              color="#fff"
              z-index="1"
            >
              <v-progress-circular
                color="primary"
                indeterminate
                size="50"
              ></v-progress-circular>
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import BulkImportTemplate from "./BulkImportTemplate.vue";
import {
  GET_VENDOR_DETAILS,
  SAVE_PROJECT_RESOURCES,
} from "@/graphql/vendorOnboardQueries";
export default {
  name: "ProjectResource",

  components: { BulkImportTemplate },

  data: () => ({
    noOfEmployeesWithinOrganization: "",
    noOfSubcontractors: "",
    noOfEmployeesToBeAllocatedToContract: "",
    addExperience: [],
    fromModal: false,
    toModal: false,
    maxYear: "",
    minJoinDate: new Date(localStorage.getItem("DOB"))
      .toISOString()
      .slice(0, 10),
    saveLoading: false,
    apiCallCount: 0,
    personalInfoLoading: true,
    showSaveAndContinue: true,
    openBottomSheet: true,
    showBulkImport: false,
  }),

  created() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    this.maxYear = yyyy + "-" + mm + "-" + dd;
  },
  apollo: {
    getVendorDetails: {
      query: GET_VENDOR_DETAILS,
      client: "apolloClientB",
      fetchPolicy: "no-cache",
      variables() {
        return {
          urlHash: localStorage.getItem("vendorUrlHash"),
        };
      },
      result({ data }) {
        if (
          data &&
          data.getVendorDetails &&
          data.getVendorDetails.vendorDetails &&
          data.getVendorDetails
        ) {
          this.noOfEmployeesWithinOrganization =
            data.getVendorDetails.vendorDetails.noOfEmployeesWithinOrganization;
          this.noOfSubcontractors =
            data.getVendorDetails.vendorDetails.noOfSubcontractors;
          this.noOfEmployeesToBeAllocatedToContract =
            data.getVendorDetails.vendorDetails.noOfEmployeesToBeAllocatedToContract;
        }
        this.personalInfoLoading = false;
      },
      // Error handling
      error(getPersonalInfoErr) {
        let snackbarData = {
          isOpen: true,
          message:
            getPersonalInfoErr.graphQLErrors.length !== 0
              ? getPersonalInfoErr.graphQLErrors[0].message
              : "Something went wrong. It could be a possibility due to network connectivity error..",
          type: "warning",
        };
        this.showAlert(snackbarData);
        this.personalInfoLoading = false;
      },
    },
  },

  methods: {
    onDisplaySaveContinue(val) {
      this.showSaveAndContinue = val;
    },
    onClickPrevBtn() {
      this.$emit("back-to-prev");
    },
    onSkipProjectResource() {
      this.$emit("move-to-next-section");
    },
    onSaveProjectResource() {
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.$refs.projectResourceForm.validate().then((isValid) => {
        if (isValid) {
          this.submitProjectResource();
        }
      });
    },
    submitProjectResource() {
      let vm = this;
      vm.saveLoading = true;
      let vendorId = parseInt(localStorage.getItem("vendorId"));
      let submitObj = {
        vendorId: vendorId,
        noOfEmployeesWithinOrganization: parseInt(
          vm.noOfEmployeesWithinOrganization
        ),
        noOfSubcontractors: parseInt(vm.noOfSubcontractors),
        noOfEmployeesToBeAllocatedToContract: parseInt(
          vm.noOfEmployeesToBeAllocatedToContract
        ),
      };
      vm.$apollo
        .mutate({
          mutation: SAVE_PROJECT_RESOURCES,
          variables: submitObj,
          client: "apolloClientC",
        })
        .then(() => {
          vm.saveLoading = false;
          vm.showBulkImport = true;
        })
        .catch((updateErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              updateErr.graphQLErrors.length !== 0
                ? updateErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
          vm.personalInfoLoading = false;
          vm.saveLoading = false;
        });
    },
    // show error or success message
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>
