<template>
  <div>
    <v-row justify="center" align="center">
      <v-col cols="12" md="11" sm="12" xs="12">
        <v-row>
          <v-col
            cols="12"
            sm="12"
            lg="6"
            md="6"
            xl="6"
            class="step-content-col"
          >
            <v-row>
              <v-col cols="12" class="pa-0 mb-2">
                <div class="d-flex">
                  <v-avatar color="light-blue lighten-4">
                    <v-icon color="blue">cloud_download</v-icon>
                  </v-avatar>
                  <div class="primary--text font-weight-bold text-h6 pl-sm-2">
                    Download the excel template with predefined headers
                  </div>
                </div>
                <div class="ml-sm-5">
                  <div class="primary--text text--lighten-4 mt-2 pl-sm-8">
                    You have the option of using our predefined template or
                    bring in your own employee data sheet with the headers for
                    import
                    <div class="mt-1">
                      <v-btn
                        id="bulk_sheet_download"
                        rounded
                        small
                        color="primary"
                        class="font-weight-bold"
                        @click="generateAndExportExcelFile()"
                      >
                        <v-icon class="mr-1" size="16">cloud_download</v-icon>
                        Download
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="pa-0 mb-2">
                <div class="d-flex">
                  <v-avatar color="#b5ffb0">
                    <v-icon color="green">cloud_upload</v-icon>
                  </v-avatar>
                  <div class="primary--text font-weight-bold text-h6 pl-sm-2">
                    Map the imported template headers with properties for import
                  </div>
                </div>
                <div class="ml-sm-5">
                  <div class="primary--text text--lighten-4 mt-2 pl-sm-8">
                    Map the properties as required for import. Do not worry
                    about the data as you can still edit them online like how
                    you do in excel
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="pa-0">
                <div class="d-flex">
                  <v-avatar color="#ade7ff">
                    <v-icon color="cyan">fas fa-edit</v-icon>
                  </v-avatar>
                  <div class="primary--text font-weight-bold text-h6 pl-sm-2">
                    Review, validate and adjust before processing in online
                    excel interface
                  </div>
                </div>
                <div class="ml-sm-5">
                  <div class="primary--text text--lighten-4 mt-2 pl-sm-8">
                    You can review the data and amend as required before
                    validation and processing
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            lg="6"
            md="6"
            xl="6"
            class="step-content-col"
            :class="!fileContent ? 'mt-10' : ''"
          >
            <v-row justify="center">
              <v-col cols="12">
                <file-pond
                  v-if="!fileContent"
                  ref="vueFilePond"
                  name="test"
                  label-idle="<div class='custom-file-upload-label-icon'>
                                                            <i class='fas fa-cloud-upload-alt upload-icon' style='color: grey;font-size: 70px;'></i>
                                                            </div>
                                                            <div class='custom-file-pond-label-header'>
                                                                    <span class='custom-file-pond-label-sub-header1'>Choose a file </span> 	&nbsp; 
                                                                    <span class='custom-file-pond-label-sub-header2'> or drag it here.</span>
                                                            </div>
                                                            <div class='custom-label-less-than-450' style='margin-top: 40px'>
                                                                <div style='text-align: center;'><span class='custom-file-pond-label-sub-header1'>Choose a file</span> or drag it here.</div>
                                                                <div style='text-align: center;'>*All .Csv, .Xlsx, And .Xls file types are supported.</div>
                                                            </div>
                                                            <div class='custom-file-pond-label-content'>*All .Csv, .Xlsx, And .Xls file types are supported.</div>"
                  accepted-file-types="text/csv, application/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  @addfile="getFileContent"
                  @removefile="removeFileContent"
                />
                <v-card
                  v-else
                  class="card-radius common-box-shadow"
                  color="cyan lighten-5"
                >
                  <v-card-title class="d-flex justify-end">
                    <v-icon class="mt-n2" @click="removeFileContent"
                      >close</v-icon
                    >
                  </v-card-title>
                  <v-card-text
                    class="d-flex justify-center align-center text-center mx-auto flex-column mb-4 mt-n4 pa-5"
                  >
                    <img
                      width="100px"
                      height="auto"
                      src="../../../assets/images/file-preview.png"
                      alt="shares file preview"
                      class="mx-auto mb-4"
                    />
                    <div class="title mt-3" style="max-width: 300px">
                      {{ fileProperties[0] }}
                    </div>
                    <div class="mt-2">{{ fileProperties[1] }}</div>
                    <v-btn
                      dense
                      small
                      rounded
                      class="mt-4"
                      color="grey lighten-2"
                      @click="removeFileContent"
                      >Remove and upload again</v-btn
                    >
                    <br />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// plugins
import * as XLSX from "xlsx";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
// mixins
import FileExportMixin from "@/mixins/FileExportMixin";
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

export default {
  name: "BulkImportStep1",
  components: {
    FilePond,
  },

  props: {
    locationList: {
      type: Array,
      required: true,
    },
    departmentList: {
      type: Array,
      required: true,
    },
    designationList: {
      type: Array,
      required: true,
    },
    workScheduleList: {
      type: Array,
      required: true,
    },
    empTypeList: {
      type: Array,
      required: true,
    },
    managerList: {
      type: Array,
      required: true,
    },
    validityList: {
      type: Array,
      required: true,
    },
  },

  mixins: [FileExportMixin],

  data: () => ({
    fileContent: null,
    fileProperties: [],
    nominateList: [
      { Nominate_Admin: "Yes", value: 1 },
      { Nominate_Admin: "No", value: 0 },
    ],
  }),

  methods: {
    // on file remove, handled in plugin itself
    removeFileContent() {
      this.$emit("file-upload-error-remove");
      this.fileContent = null;
    },
    // called when file is added on vue-filepond plugin
    getFileContent(error, file) {
      // error: returned from plugin, if error, then we don't process file reade
      if (!error) {
        // define fire reader
        var reader = new FileReader();
        // on-loading file
        reader.onload = (e) => {
          let uploadedFile = file.file,
            fileSize = uploadedFile.size / 1000;
          this.fileProperties = [uploadedFile.name, fileSize + "kb"];
          /* Parse data */
          const binaryToString = e.target.result;
          // reading binary file value using XLSX
          const workBook = XLSX.read(binaryToString, {
            type: "binary",
          });

          /* Get first worksheet */
          const workSheetName = workBook.SheetNames[0];
          const workSheet = workBook.Sheets[workSheetName];

          /* Convert array of arrays */
          const fileContent = XLSX.utils.sheet_to_json(workSheet, {
            header: 1,
            raw: false, // to read the data as string
          });
          this.fileContent = fileContent;
          // return excel as row-object(to check empty file)
          var XL_row_object = XLSX.utils.sheet_to_row_object_array(workSheet);
          if (XL_row_object.length > 0 && fileContent[0].length > 0)
            // emit the file content
            this.$emit("file-upload-success", fileContent);
          else this.showAlert(XL_row_object, fileContent);
        };
        reader.onerror = function (ex) {
          if (ex) this.$emit("file-upload-error-remove");
        };
        reader.readAsBinaryString(file.file);
      } else {
        this.fileContent = null;
        this.$emit("file-upload-error-remove");
      }
    },
    generateAndExportExcelFile() {
      let exportOptions = {
        fileExportData: [],
        fileName: "Candidate Onboard",
        sheetName: "Candidate Onboard",
        header: [
          { key: "First_Name", header: "Employee First Name" },
          { key: "Last_Name", header: "Employee Last Name" },
          { key: "Email", header: "Email Address" },
          { key: "Date_Of_join", header: "Date Of Join (YYYY-MM-DD)" },
          { key: "Location_Id", header: "Location" },
          { key: "Department_Id", header: "Department" },
          { key: "Designation_Id", header: "Designation" },
          { key: "WorkSchedule_Id", header: "Work Schedule" },
          { key: "EmpType_Id", header: "Employee Type" },
          { key: "Employee_Id", header: "Manager" },
          { key: "Nominate_Admin", header: "Nominate as Admin" },
          { key: "URL_Validity", header: "URL Validity" },
          { key: "URL_Validity_Duration", header: "URL Validity Duration" },
        ],
        validationColumns: [
          {
            validationList: this.locationList,
            validationKeyName: "Location_Name",
            validationCell: "P",
            valueCell: "E",
            hiddenColumn: 16,
          },
          {
            validationList: this.departmentList,
            validationKeyName: "Department_Name",
            validationCell: "Q",
            valueCell: "F",
            hiddenColumn: 17,
          },
          {
            validationList: this.designationList,
            validationKeyName: "Designation_Name",
            validationCell: "R",
            valueCell: "G",
            hiddenColumn: 18,
          },
          {
            validationList: this.workScheduleList,
            validationKeyName: "Title",
            validationCell: "S",
            valueCell: "H",
            hiddenColumn: 19,
          },
          {
            validationList: this.empTypeList,
            validationKeyName: "Employee_Type",
            validationCell: "T",
            valueCell: "I",
            hiddenColumn: 20,
          },
          {
            validationList: this.managerList,
            validationKeyName: "Emp_First_Name",
            validationCell: "U",
            valueCell: "J",
            hiddenColumn: 21,
          },
          {
            validationList: this.nominateList,
            validationKeyName: "Nominate_Admin",
            validationCell: "W",
            valueCell: "K",
            hiddenColumn: 23,
          },
          {
            validationList: this.validityList,
            validationKeyName: "Type",
            validationCell: "V",
            valueCell: "M",
            hiddenColumn: 22,
          },
        ],
      };
      this.exportExcelFile(exportOptions);
    },
  },
};
</script>

<style>
@import url("../../../assets/styles/customFilePondDocUploader.css");
</style>
