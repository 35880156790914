import { Workbook } from "exceljs";
import * as fs from "file-saver";

export default {
  methods: {
    // export xlsx file
    exportExcelFile(fileOptions) {
      // file options that is passed to this function
      const {
        sheetName,
        header,
        fileName,
        fileExportData,
        xSplit = 0,
        headerRowNumber = [1],
        headerColor = "92CDDC", // default color
        isHighlightRow = false,
        highlightCell,
        validationColumns = [],
      } = fileOptions;

      // Create workbook and main worksheet
      let workbook = new Workbook();
      let mainWorksheet = workbook.addWorksheet(sheetName);
      mainWorksheet.columns = header;

      // Add data to main worksheet
      if (fileExportData && fileExportData.length > 0) {
        mainWorksheet.addRows(fileExportData);
      }
      // header styles (background and border)
      if (headerRowNumber && headerRowNumber.length > 0) {
        for (let headerRow of headerRowNumber) {
          mainWorksheet.getRow(headerRow).eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: headerColor },
            };
            cell.alignment = { vertical: "middle", horizontal: "center" };
            cell.font = {
              name: "Calibri",
              size: 11,
              bold: true,
            };
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          });
        }
        // freeze column and row
        mainWorksheet.views = [
          {
            state: "frozen",
            xSplit: xSplit,
            ySplit: headerRowNumber.length === 1 ? headerRowNumber[0] : 0,
          },
        ];
      }
      // highlight row based on passed cell value
      fileExportData.map((val, index) => {
        let indexplusone = index + 2;
        if (val[highlightCell] === "Yes" && isHighlightRow) {
          mainWorksheet.getRow(indexplusone).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ffe4c9" },
          };
          mainWorksheet.getRow(indexplusone).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      });
      // auto width of column based on value length
      mainWorksheet.columns.forEach((column) => {
        const lengths = column.values.map((v) => v.toString().length);
        const maxLength = Math.max(
          ...lengths.filter((v) => typeof v === "number")
        );
        column.width = maxLength;
      });
      if (validationColumns && validationColumns.length > 0) {
        // Create a new sheet for validation lists
        let validationSheet = workbook.addWorksheet("ValidationLists");

        // Add validation lists to the validation sheet
        validationColumns.forEach((validationColumn, index) => {
          let dropdownList = validationColumn.validationList;
          let columnLetter = String.fromCharCode(65 + index); // Get column letter for reference
          dropdownList.forEach((item, i) => {
            validationSheet.getCell(`${columnLetter}${i + 1}`).value =
              item[validationColumn.validationKeyName];
          });
        });

        // Reference validation lists in the main worksheet
        validationColumns.forEach((validationColumn, index) => {
          let columnLetter = String.fromCharCode(65 + index);
          let dropdownListLength = validationColumn.validationList.length;
          let dropdownValidation = `ValidationLists!$${columnLetter}$1:$${columnLetter}$${dropdownListLength}`;
          for (var rowIndex = 1; rowIndex <= 1000; rowIndex++) {
            mainWorksheet.getCell(
              `${validationColumn.valueCell}${rowIndex + 1}`
            ).dataValidation = {
              type: "list",
              allowBlank: false,
              formulae: [dropdownValidation],
            };
          }
          // Hide the validation sheet
          validationSheet.state = "hidden";
        });
      }

      // generate excel file with given file name
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fs.saveAs(blob, fileName);
      });
    },

    // export CSV file
    exportCSV(fileOptions) {
      // file options that is passed to this function
      const { sheetName, header, fileName, fileExportData } = fileOptions;
      // Create workbook and worksheet
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet(sheetName);
      worksheet.columns = header;
      // assign data in each row
      if (fileExportData && fileExportData.length > 0) {
        worksheet.addRows(fileExportData);
      }
      // generate excel file with given file name
      workbook.csv.writeBuffer().then((data) => {
        let blob = new Blob([data], {
          type: "text/csv;charset=utf-8",
        });
        fs.saveAs(blob, fileName);
      });
    },
  },
};
