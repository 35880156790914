<template>
  <div class="full-height">
    <div v-if="!isFormCompleted">
      <div v-if="windowWidth > 960" class="mt-4 d-flex justify-center">
        <div
          v-for="(detail, index) in progressDetails"
          :key="detail.type"
          class="text-center"
        >
          <div class="d-flex align-center">
            <div>
              <v-progress-circular
                :rotate="-90"
                :size="45"
                :width="5"
                :value="
                  index === selectedSection || selectedSection > index ? 100 : 0
                "
                :color="selectedSection > index ? 'green' : 'primary'"
              >
                <v-icon
                  size="20"
                  class="font-weight-bold"
                  :color="selectedSection > index ? 'green' : 'primary'"
                >
                  {{ selectedSection > index ? "check" : detail.icon }}</v-icon
                >
              </v-progress-circular>
              <div
                :class="{ 'font-weight-bold': index === selectedSection }"
                class="body-2 mt-1"
                :style="
                  selectedSection > index
                    ? 'color: green'
                    : 'color: var(--primary-base)'
                "
              >
                {{ detail.heading }}
              </div>
            </div>
            <div
              v-if="detail.type !== 'submit'"
              style="width: 70px; border-top: 2px dotted #b1afaf"
            ></div>
          </div>
        </div>
      </div>
      <VendorBasicDetails
        v-if="selectedSection === 0"
        @basic-details-saved="onClickNextSession(1)"
        @enable-dynamic-form="enableDynamicForm($event)"
      ></VendorBasicDetails>
      <Insurance
        v-else-if="selectedSection === 1"
        @back-to-prev="backToPreviousStep(0)"
        @move-to-next-section="onClickNextSession(2)"
      ></Insurance>
      <VendorDocuments
        v-else-if="selectedSection === 2"
        @back-to-prev="backToPreviousStep(1)"
        @vendor-documents-saved="onClickNextSession(3)"
      ></VendorDocuments>
      <VendorBankDetails
        v-else-if="selectedSection === 3"
        @back-to-prev="backToPreviousStep(2)"
        @bank-saved="onClickNextSession(4)"
      ></VendorBankDetails>
      <ProjectResources
        v-else-if="selectedSection === 4"
        @back-to-prev="backToPreviousStep(3)"
        @move-to-next-section="onClickNextSession(5)"
      ></ProjectResources>
      <VendorDynamicForm
        v-else-if="selectedSection === 5"
        :template-id="dynamicFormTemplateId"
        :template-name="dynamicFormDataTemplateName"
        :form-data="dynamicFormData"
        :response-id="dynamicFormResponseId"
        @back-to-prev="backToPreviousStep(4)"
        @move-to-next-section="onClickNextSession(6)"
      ></VendorDynamicForm>
      <FormSubmit
        v-else
        @back-to-prev="backToPreviousStep(5)"
        @form-submitted="isFormCompleted = true"
      ></FormSubmit>
    </div>

    <div v-else class="full-height">
      <v-row justify="center" class="full-height">
        <v-col
          cols="12"
          sm="10"
          class="justify-center d-flex align-center"
          :class="isMobileView ? 'flex-column text-center' : ''"
        >
          <v-icon size="100" color="green">check_circle</v-icon>
          <div class="pl-4">
            <div class="text-h4 primary--text">
              You have successfully completed the onboarding
            </div>
            <div class="grey--text text--darken-1 text-h5 mt-3">
              We have sent it for review
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import VendorBasicDetails from "./VendorBasicDetails.vue";
import ProjectResources from "./ProjectResource.vue";
import Insurance from "./insurance-details/Insurance.vue";
import FormSubmit from "./FormSubmit.vue";
import VendorDocuments from "./documents/VendorDocuments.vue";
import VendorDynamicForm from "./VendorDynamicForm.vue";
import VendorBankDetails from "./VendorBankDetails.vue";

import { GET_DYNAMIC_FORM_DETAILS } from "@/graphql/vendorOnboardQueries";

export default {
  name: "VendorOnboarding",

  components: {
    VendorBasicDetails,
    Insurance,
    ProjectResources,
    FormSubmit,
    VendorDocuments,
    VendorDynamicForm,
    VendorBankDetails,
  },

  data: () => ({
    selectedSection: 0,
    isFormCompleted: false,
    progressDetails: [
      {
        type: "basic",
        progress: 0,
        value: 0,
        icon: "person",
        heading: "Vendor Basic Information",
      },
      {
        type: "insurance",
        progress: 0,
        value: 1,
        icon: "business_center",
        heading: "Insurance",
      },
      {
        type: "documents",
        value: 2,
        icon: "upload_file",
        heading: "Documents",
      },
      {
        type: "bank",
        value: 3,
        icon: "account_balance",
        heading: "Bank Information",
      },
      {
        type: "projectResources",
        progress: 0,
        value: 4,
        icon: "description",
        heading: "Project Resources",
      },
      // {
      //   type: "complianceQuestionnaire",
      //   progress: 0,
      //   value: 3,
      //   icon: "article",
      //   heading: "Compliance Questionnaire",
      // },
      {
        type: "submit",
        value: 5,
        icon: "checklist_rtl",
        heading: "Submit",
      },
    ],
    dynamicFormTemplateId: 0,
    dynamicFormData: [],
    dynamicFormResponseId: 0,
    dynamicFormDataTemplateName: "",
  }),

  computed: {
    // current window size
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    // to check the device is mobile based on window size
    isMobileView() {
      return this.$store.state.isMobileWindowSize;
    },
  },

  methods: {
    enableDynamicForm(templateId) {
      this.dynamicFormTemplateId = templateId;
      //change progressDetails
      this.progressDetails = [
        {
          type: "basic",
          progress: 0,
          value: 0,
          icon: "person",
          heading: "Vendor Basic Information",
        },
        {
          type: "insurance",
          progress: 0,
          value: 1,
          icon: "business_center",
          heading: "Insurance",
        },
        {
          type: "documents",
          value: 2,
          icon: "upload_file",
          heading: "Documents",
        },
        {
          type: "bank",
          value: 3,
          icon: "account_balance",
          heading: "Bank Information",
        },
        {
          type: "projectResources",
          progress: 0,
          value: 4,
          icon: "description",
          heading: "Project Resources",
        },
        {
          type: "complianceQuestionnaire",
          progress: 0,
          value: 5,
          icon: "article",
          heading: "Compliance Questionnaire",
        },
        {
          type: "submit",
          value: 6,
          icon: "checklist_rtl",
          heading: "Submit",
        },
      ];
      this.getDynamicFormDetails(templateId);
    },
    getDynamicFormDetails(templateId) {
      let vm = this;
      vm.$apollo
        .query({
          query: GET_DYNAMIC_FORM_DETAILS,
          variables: {
            templateId: templateId,
            vendorId: parseInt(localStorage.getItem("vendorId")),
          },
          fetchPolicy: "no-cache",
          client: "apolloClientB",
        })
        .then((data) => {
          data = data.data;
          if (data && data.getDynamicFormDetails) {
            let formData = data.getDynamicFormDetails.dynamicFormDetails[0];
            this.dynamicFormData = JSON.parse(formData.Form_Template);
            if (formData.Response_Id) {
              this.dynamicFormResponseId = formData.Response_Id;
              this.dynamicFormDataTemplateName = formData.Template_Name;
            }
          }
        })
        .catch((err) => {
          let snackbarData = {
            isOpen: true,
            message:
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
        });
    },
    onClickNextSession(nextStep) {
      if (nextStep === 5) {
        if (this.dynamicFormTemplateId) {
          this.selectedSection = nextStep;
        } else {
          this.selectedSection = 6;
        }
      } else {
        this.selectedSection = nextStep;
      }
    },
    backToPreviousStep(toStep) {
      if (toStep === 5) {
        if (this.dynamicFormTemplateId) {
          this.selectedSection = toStep;
        } else {
          this.selectedSection = 4;
        }
      } else {
        this.selectedSection = toStep;
      }
    },
    // show error or success message
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>
