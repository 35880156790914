<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" xs="12" sm="11" md="11" lg="10" xlg="10">
        <div class="d-flex align-center">
          <v-icon color="deep-purple lighten-2">trip_origin</v-icon>
          <span class="ml-2 step2-header primary--text font-weight-bold"
            >Map columns in your file to {{ companyName }} properties</span
          >
        </div>
        <div class="mt-3 primary--text text--lighten-4 ml-2 mb-4">
          Each column header below should be mapped to an
          {{ companyName }} property. Some of these have already been mapped
          based on their names. Anything that hasn't been mapped yet can be
          manually mapped to a {{ companyName }} property with the dropdown
          menu.
        </div>
        <VuePerfectScrollbar
          class="scroll-area"
          :settings="perfectScrollbarSettings"
        >
          <div class="mapping-table mb-4">
            <v-data-table
              :headers="headers"
              :items="tableItems"
              hide-default-footer
              fix-header
              :items-per-page="100"
            >
              <template #[`header.matched`]="{}">
                <div
                  class="custom-table-headers font-weight-bold body-2"
                  color="grey darken-3"
                >
                  Matched
                </div>
              </template>
              <template #[`header.file_header`]="{}">
                <div
                  class="custom-table-headers font-weight-bold body-2"
                  color="grey darken-3"
                >
                  Column header from file
                </div>
              </template>
              <template #[`header.file_preview`]="{}">
                <div
                  class="custom-table-headers font-weight-bold body-2"
                  color="grey darken-3"
                >
                  Preview information
                </div>
              </template>
              <template #[`header.hrapp_header`]="{}">
                <div
                  class="custom-table-headers font-weight-bold body-2"
                  color="grey darken-3"
                >
                  {{ companyName }} Property
                </div>
              </template>
              <template #item="{ item }">
                <tr
                  class="data-table-tr common-box-shadow"
                  :class="
                    windowWidth <= 600 ? ' v-data-table__mobile-table-row' : ''
                  "
                >
                  <td
                    :class="windowWidth < 600 ? 'v-data-table__mobile-row' : ''"
                  >
                    <v-icon v-if="item.matched" color="green accent-3" size="30"
                      >fas fa-check-circle</v-icon
                    >
                    <v-icon v-else color="red accent-2" size="30"
                      >fas fa-times-circle</v-icon
                    >
                  </td>

                  <td
                    :class="
                      windowWidth <= 600 ? 'v-data-table__mobile-row' : ''
                    "
                  >
                    <div
                      v-if="windowWidth <= 600"
                      class="v-data-table__mobile-row__header"
                    >
                      File Header
                    </div>
                    <div class="primary--text body-1 font-weight-bold">
                      {{ item.file_header }}
                    </div>
                  </td>

                  <td
                    :class="
                      windowWidth <= 600 ? 'v-data-table__mobile-row' : ''
                    "
                  >
                    <div
                      v-if="windowWidth <= 600"
                      class="v-data-table__mobile-row__header"
                    >
                      Preview
                    </div>
                    <div class="primary--text body-1 font-weight-bold">
                      {{ item.file_preview }}
                    </div>
                  </td>

                  <td
                    :class="
                      windowWidth <= 600
                        ? 'v-data-table__mobile-row d-flex flex-column'
                        : ''
                    "
                    :style="windowWidth <= 600 ? 'height: 7em' : ''"
                  >
                    <div
                      v-if="windowWidth <= 600"
                      class="v-data-table__mobile-row__header"
                    >
                      {{ companyName }} Property
                    </div>
                    <div class="pt-4"></div>
                    <v-select
                      v-model="item.hrapp_header"
                      style="max-width: 250px"
                      :items="predefinedHeader"
                      solo
                      item-color="primary"
                      background-color="#ffebeb"
                      clearable
                      @change="updateMatchedColumn(item)"
                    ></v-select>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </VuePerfectScrollbar>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "BulkImportStep2",
  props: {
    fileParams: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    companyName: "",
    // list in select field
    predefinedHeader: [
      {
        text: "Employee First Name",
        disabled: false,
      },
      {
        text: "Employee Last Name",
        disabled: false,
      },
      {
        text: "Email Address",
        disabled: false,
      },
      {
        text: "Date Of Join (YYYY-MM-DD)",
        disabled: false,
      },
      {
        text: "Location",
        disabled: false,
      },
      {
        text: "Department",
        disabled: false,
      },
      {
        text: "Designation",
        disabled: false,
      },
      {
        text: "Work Schedule",
        disabled: false,
      },
      {
        text: "Employee Type",
        disabled: false,
      },
      {
        text: "Manager",
        disabled: false,
      },
      {
        text: "Nominate as Admin",
        disabled: false,
      },
      {
        text: "URL Validity",
        disabled: false,
      },
      {
        text: "URL Validity Duration",
        disabled: false,
      },
    ],
    // table header
    headers: [
      {
        text: "Matched",
        align: "start",
        sortable: false,
        value: "matched",
        class: "white--text primary",
      },
      {
        text: "Column header from file",
        value: "file_header",
        class: "white--text primary",
      },
      {
        text: "Preview information",
        value: "file_preview",
        class: "white--text primary",
      },
      {
        text: "HRAPP Property",
        value: "hrapp_header",
        class: "white--text primary",
      },
    ],
    tableItems: [],
    mappedCount: 0,
  }),
  computed: {
    perfectScrollbarSettings() {
      return this.$store.state.smallScrollbarSettings;
    },

    // current window size
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  mounted() {
    this.companyName = localStorage.getItem("companyName");
    // to form table items based on file header which is returned from step 1
    if (this.fileParams[0].length > 0) {
      // 0: headers list
      let tableData = [];
      for (let i in this.fileParams[0]) {
        // don't allow Data_Validation column for mapping. because its added for validation alone.
        if (this.fileParams[0][i] !== "Data_Validation") {
          // check file header value is available in our predefined header
          let headerIndex = this.predefinedHeader.findIndex((obj) => {
            let headerName = obj.text;
            return headerName === this.fileParams[0][i];
          });
          let tableObject = {
            mapping_id: i,
            matched: headerIndex > -1 ? 1 : 0, // file header is matched to our column, then set as matched, else set as unmatched
            file_header: this.fileParams[0][i], // headers list
            file_preview: this.fileParams[1][i]
              ? this.fileParams[1][i]
              : "Nothing to preview", // preview the first column of each excel cell
            hrapp_header:
              headerIndex > -1
                ? this.predefinedHeader[headerIndex]["text"]
                : "", // file header is matched to our column, then set the header value, else set as empty
          };
          tableData.push(tableObject);
        }
      }
      this.tableItems = tableData;
      this.findMatchedColumn();
    }
  },
  methods: {
    // find matched column and disable selected value in hrapp property select field
    findMatchedColumn() {
      // if the row data is disabled, then this is mapped rom. So for mapped count we can use disabled count
      let disabledCount = 0,
        mappedFileHeader = [];
      // check two array to find same value, and disable it to prevent selecting duplicate one
      for (let i in this.predefinedHeader) {
        for (let j in this.tableItems) {
          if (
            this.tableItems[j].hrapp_header === this.predefinedHeader[i].text
          ) {
            disabledCount++;
            mappedFileHeader.push({
              file_header: j,
              hrapp_header: this.predefinedHeader[i].text,
            }); // to find the file header index
            this.predefinedHeader[i]["disabled"] = true;
            break;
          } else {
            this.predefinedHeader[i]["disabled"] = false;
          }
        }
      }
      this.mappedCount = disabledCount; // total columns mapped count
      this.$emit("column-mapped", [this.mappedCount, mappedFileHeader]);
    },
    // on change function for hrapp property header selection
    updateMatchedColumn(item) {
      this.findMatchedColumn();
      // we have to find index of change array
      let index = this.tableItems.findIndex(
        (obj) => obj.mapping_id === item.mapping_id
      );
      // if the selected header is removed we have to set the row as not-matched
      if (item.hrapp_header) {
        this.tableItems[index]["matched"] = 1; // if the header is selected, then set that row as matched
      } else {
        this.tableItems[index]["matched"] = 0; // if the header is removed, then set that row as un-matched
      }
    },
  },
};
</script>

<style lang="css">
@import "../../../assets/styles/customTooltip.css";
@import "../../../assets/styles/dataTableStyle.scss";

.step2-header {
  font-size: 1.4em;
}
.mapping-table {
  height: 600px;
}
.custom-table-headers {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 160px;
  display: inline-block;
}
.pages-list:hover {
  background: #ffebeb;
}
@media screen and (max-width: 700px) {
  .step1-header {
    font-size: 1.2em;
  }
}
@media (max-width: 600px) {
  .v-data-table thead tr:last-child th {
    display: none !important;
    margin-bottom: -60px;
  }
  .mapping-table {
    height: 100%;
  }
}
@media screen and (max-width: 600px) {
  .theme--light.v-data-table {
    background-color: #a7a7a714 !important;
  }
}
</style>
